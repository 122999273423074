@import './../../../../scss/theme-bootstrap';

.ie-upgrade {
  background-color: $color-overlay-dark;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
  html.ie8 &,
  html.ie7 &,
  html.ie6 & {
    background-color: $color-black;
  }
  &__content {
    @include transform(translate(-50%, -50%));
    background-color: $color-pink-2;
    color: $color-black;
    left: 50%;
    max-width: 500px;
    padding: 30px 20px 20px 20px;
    position: relative;
    text-align: center;
    top: 50%;
    width: 60%;
    z-index: 10001;
    @include breakpoint($landscape-up) {
      padding: 40px 35px 30px 35px;
    }
    html.ie8 &,
    html.ie7 &,
    html.ie6 & {
      left: 20%;
      top: 20%;
    }
    #cboxClose:before {
      @include breakpoint($medium-up) {
        font-size: 40px;
      }
    }
    &-copy {
      font-size: 14px;
      line-height: 19px;
      padding: 25px 30px;
      @include breakpoint($landscape-up) {
        font-size: 16px;
      }
    }
    h3 {
      color: $color-black;
      font-size: 26px;
      line-height: 26px;
      text-transform: uppercase;
      width: 85%;
      @include breakpoint($landscape-up) {
        font-size: 28px;
      }
    }
    p {
      line-height: inherit;
    }
  }
  &__button {
    .site-footer & {
      margin: 0 auto;
      padding: 1em;
      width: 30%;
    }
  }
}
